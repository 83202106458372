<template>
  <div class="schedule-editor">
    <XRadioGroup
      class="schedule-editor__type-radios"
      v-model="scheduleTypeComputed"
      :items="SCHEDULE_TYPE_ITEMS"
      :help="getHelpButtonId('scheduling_type')"
    />

    <keep-alive>
      <ListEditor
        v-if="scheduleTypeComputed === SCHEDULE_TYPE.DEFAULT"
        v-model="modelValue"
        :columns="SCHEDULE_COLUMNS"
        :help="getHelpButtonId('default_timing_scheduling')"
      />

      <CronEditor
        v-else-if="scheduleTypeComputed === SCHEDULE_TYPE.CRON"
        :cronString.sync="modelValue"
        :help="getHelpButtonId('cron_syntax_timing_scheduling')"
        @start-typing="({ isValid }) => emitValidation({ isValid })"
        @end-typing="({ isValid }) => emitValidation({ isValid })"
      />
    </keep-alive>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue"
import ListEditor from '@/components/basic/ListEditor.vue';
import XRadioGroup from '@/components/basic/XRadioGroup.vue';
import CronEditor from "@/components/extended/CronEditor.vue";
import { SCHEDULE_COLUMNS, SCHEDULE_TYPE_ITEMS, SCHEDULE_TYPE } from "@/composition/schedule-tests/use-scheduling"
import { useHelpButton } from "@/composition/help-button"

const HELP_BTN_PREFIX = "Schedule_tests_editor"

export default defineComponent({
  name: "ScheduleEditor",

  components: {
    CronEditor,
    XRadioGroup,
    ListEditor,
  },

  props: {
    scheduleType: {
      type: String,
      required: true,
    },

    scheduleData: {
      type: [Object, Array, String],
      required: true,
    }
  },

  emits: ["update:scheduleType", "update:scheduleData", "validation"],

  setup(props, { emit }) {
    const scheduleTypeComputed = computed({
      get: () => props.scheduleType,
      set: (value) => {
        if (value === SCHEDULE_TYPE.DEFAULT) {
           emitValidation({ isValid: true })
        }
        emit("update:scheduleType", value)
      },
    });

    const modelValue = computed({
      get: () => props.scheduleData,
      set: (value) => emit("update:scheduleData", value),
    });

    const emitValidation = ({ isValid }) => {
      emit("validation", { isValid })
    }

    const {
      getHelpButtonId,
    } = useHelpButton({ prefix: HELP_BTN_PREFIX })

    return {
      getHelpButtonId,
      SCHEDULE_COLUMNS,
      SCHEDULE_TYPE_ITEMS,
      SCHEDULE_TYPE,
      emitValidation,
      scheduleTypeComputed,
      modelValue,
    }
  },
});
</script>

<style lang="scss">
.schedule-editor {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__type-radios {
    align-items: center;
  }
}
</style>
