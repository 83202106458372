<template>
  <HistoryFormDialog
    class="schedule-tests-dialog"
    :value="value"
    :item="dataScheduledTest"
    item-name="Scheduled Test"
    width="870"
    height="800"
    :viewing-history="viewingHistory"
    :is-save-loading="isSaveLoading"
    :external-is-valid="isFormValid"
    @input="() => closeThisDialog({ wasSaved: false })"
    @save="save"
  >
    <template #dialog-content>
      <div class="schedule-tests-dialog__form">
        <XRadioGroup
          class="schedule-tests-dialog__test-types-radios"
          v-model="currentTestTypeComputed"
          label="Action"
          :items="[SCHEDULED_TEST_TYPES.TEST_CASE, SCHEDULED_TEST_TYPES.CATEGORY]"
          required
          :help="getHelpButtonId('tests_types_radios')"
        />

        <keep-alive>
          <XSelect
            v-if="!isCategoryTestCase"
            v-model="currentTestTypeId"
            :items="testCases"
            item-text="name"
            item-value="id"
            label="Test Case"
            :rules="[v => !!v || 'Test Case is required']"
            :help="getHelpButtonId('test_case_category_select')"
          />

          <XSelect
            v-else
            v-model="currentTestTypeId"
            :items="categories"
            label="Test Case Category"
            :rules="[v => !!v || 'Test Case Category is required']"
            :help="getHelpButtonId('test_case_category_select')"
          />
        </keep-alive>

        <XTextField
          v-model="scheduledTestName"
          label="Name"
          required
          :help="getHelpButtonId('test_name_text_field')"
        />

        <XSwitch
          v-if="isAcctopusOrg || isStfGruppeOrg"
          v-model="dataScheduledTest.offcloud"
          :disabled="!isPossibleToSwitchOffcloudTesting"
          label="Explorer Offcloud Testing"
        />

        <HeadlineBox
          headline="Timing"
          :help="getHelpButtonId('test_timing_settings')"
          :external-is-valid="isScheduleEntriesValid"
        >
          <ScheduleEditor
            :scheduleType.sync="schedulingType"
            :scheduleData.sync="scheduleDataRepr"
            @validation="({ isValid }) => handleScheduleDataValidation({ isValid })"
          />
        </HeadlineBox>

        <HeadlineBox
          headline="Start Parameters (optional)"
          :help="getHelpButtonId('test_start_prameters')"
        >
          <div class="start-parameters">
            <HeadlineBox
                id="configure_and_run_tests_dialog_tag_query"
                :headline="`Explorer tag filter${tagQueryExplorerCount ? ` (${tagQueryExplorerCount} Explorers)`: ''}`"
                collapsible
                :value="isExplorerTagFieldOpened"
                light
            >
              <TagQueryTextField2
                :tag-filter-query.sync="dataScheduledTest.data.startParameters.explorerTagSyntax"
                @start-typing="({ isValid, count }) => handleExplTagQueryValidation({ isValid, count })"
                @validation="({ isValid, count }) => handleExplTagQueryValidation({ isValid, count })"
              />
            </HeadlineBox>

            <HeadlineBox
                id="testLocalVariables_header"
                :headline="`Local Variables${dataScheduledTest.data.startParameters.localVariables ?
                 ` (${dataScheduledTest.data.startParameters.localVariables.length})`: ''}`"
                collapsible
                :value="dataScheduledTest.data.startParameters.localVariables &&
                 !!dataScheduledTest.data.startParameters.localVariables.length"
                light
            >
              <template #default="{ handleValidate }">
                <ListEditor
                  v-model="dataScheduledTest.data.startParameters.localVariables"
                  :columns="VARIABLE_COLUMNS"
                  @validate="(...args) => handleValidate(...args)"
                  :help="getHelpButtonId('tests_local_variables')"
                />
              </template>
            </HeadlineBox>

            <HeadlineBox
                id="testGlobalVariables_header"
                :headline="`Group Variables${dataScheduledTest.data.startParameters.groupVariables ?
                 ` (${dataScheduledTest.data.startParameters.groupVariables.length})`: ''}`"
                collapsible
                :value="dataScheduledTest.data.startParameters.groupVariables &&
                 !!dataScheduledTest.data.startParameters.groupVariables.length"
                light
            >
              <template #default="{ handleValidate }">
                <ListEditor
                  v-model="dataScheduledTest.data.startParameters.groupVariables"
                  :columns="VARIABLE_COLUMNS"
                  :help="getHelpButtonId('tests_group_variables')"
                  @validate="handleValidate"
                />
              </template>
            </HeadlineBox>
          </div>
        </HeadlineBox>
      </div>
    </template>
  </HistoryFormDialog>
</template>

<script>
import { defineComponent, ref, computed, inject } from "vue"
import { mapGetters } from "vuex"
import XSelect from '@/components/basic/XSelect.vue';
import XTextField from '@/components/basic/XTextField.vue';
import testCaseInfoService from '@/js/services/TestCaseInfoService';
import XRadioGroup from '@/components/basic/XRadioGroup.vue';
import XSwitch from '@/components/basic/XSwitch.vue';
import HistoryFormDialog from '@/components/extended/HistoryFormDialog.vue';
import HeadlineBox from '@/components/basic/HeadlineBox.vue';
import TagQueryTextField2 from '@/components/extended/TagQueryTextField2.vue';
import ListEditor from '@/components/basic/ListEditor.vue';
import ScheduleEditor from '@/components/extended/ScheduleEditor.vue';
import cockpitTimeSchedulingService from '@/js/services/CockpitTimeSchedulingService';
import { useScheduling, getSchedulingConfig } from "@/composition/schedule-tests/use-scheduling"
import { useHelpButton } from "@/composition/help-button"
import { MAP_ORGANIZATIONS_TO_IDS } from "@/composition/organizations-resolver"

const HELP_BTN_PREFIX = "Schedule_tests_dialog"

const SCHEDULED_TEST_TYPES = {
  TEST_CASE: {
    text: 'Test Case',
    value: 'testcase',
  },
  CATEGORY: {
    text: 'Category',
    value: 'category',
  }
}

const VARIABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    type: 'text',
    required: true,
  },
  {
    label: 'Value',
    value: 'value',
    type: 'text',
    required: true,
  },
]

export default defineComponent({
  name: "ScheduleTestsDialog",

  components: {
    ScheduleEditor,
    ListEditor,
    HeadlineBox,
    HistoryFormDialog,
    XSelect,
    XTextField,
    XRadioGroup,
    XSwitch,
    TagQueryTextField2,
  },

  props: {
    value: Boolean,
    scheduledTest: Object,
    viewingHistory: Boolean,
  },

  setup(props) {
    // eslint-disable-next-line no-undef
    const dataScheduledTest = ref(structuredClone(props.scheduledTest))
    const {
      schedulingType,
      scheduleDataRepr,
      updateWithNewSchedulingData,
      isScheduleEntriesValid,
    } = useScheduling(getSchedulingConfig(dataScheduledTest.value))
    const isSaveLoading = ref(false);
    const {
      getHelpButtonId,
    } = useHelpButton({ prefix: HELP_BTN_PREFIX })
    const { getCloseMethod } = inject("DialogsRoot")
    const closeMe = getCloseMethod("ScheduleTestsDialog")

    const doesTestHaveExplorerTag = () => {
      return Boolean(dataScheduledTest.value.data.startParameters.explorerTagSyntax)
    }
    const tagQueryExplorerCount = ref("0")
    const isExplorerTagFieldOpened = ref(doesTestHaveExplorerTag())
    const isExplorerTagQueryValid = ref(true)
    const handleExplTagQueryValidation = ({ isValid, count }) => {
      isExplorerTagQueryValid.value = isValid
      tagQueryExplorerCount.value = `${count}`
    }

    const isScheduleDataValid = ref(true)
    const handleScheduleDataValidation = ({ isValid }) => {
      isScheduleDataValid.value = isValid
    }
    const isFormValid = computed(() => {
      return isExplorerTagQueryValid.value && isScheduleDataValid.value && isScheduleEntriesValid.value
    })

    return {
      isScheduleEntriesValid,
      dataScheduledTest,
      schedulingType,
      scheduleDataRepr,
      updateWithNewSchedulingData,
      isSaveLoading,
      getHelpButtonId,
      closeMe,
      handleExplTagQueryValidation,
      tagQueryExplorerCount,
      isExplorerTagFieldOpened,
      isFormValid,
      VARIABLE_COLUMNS,
      SCHEDULED_TEST_TYPES,
      handleScheduleDataValidation,
    }
  },

  data() {
    return {
      testCases: [],
      categories: [],
      currentTestType: '',
    };
  },

  created() {
    this.initTestType();

    testCaseInfoService.getTestCasesWithResourceTypeByType('test', (testCases) => {
      this.testCases = testCases;
    });

    testCaseInfoService.getCategories((categories) => {
      this.categories = categories;
    });
  },

  computed: {
    ...mapGetters(['organizationId']),

    isAcctopusOrg() {
      return MAP_ORGANIZATIONS_TO_IDS.ACCTOPUS.includes(this.organizationId)
    },

    isStfGruppeOrg() {
      return MAP_ORGANIZATIONS_TO_IDS.STF_GRUPPE.includes(this.organizationId)
    },

    isCategoryTestCase() {
      return this.currentTestType === SCHEDULED_TEST_TYPES.CATEGORY.value
    },

    scheduledTestName: {
      get() {
        return this.dataScheduledTest.name
      },
      set(v) {
        this.dataScheduledTest.name = v
      }
    },

    currentTestTypeComputed: {
      get() {
        return this.currentTestType
      },
      set(v) {
        this.currentTestType = v
        this.setScheduledTestName(this.currentTestTypeId)
      }
    },

    currentTestTypeId: {
      get() {
        if (this.isCategoryTestCase) {
          return this.dataScheduledTest.testCase.category
        }

        return this.dataScheduledTest.testCase.id
      },
      set(v) {
        if (this.isCategoryTestCase) {
          this.dataScheduledTest.testCase.category = v
        } else {
          this.dataScheduledTest.testCase.id = v
        }
        this.setDefaultTestTypeData()
      }
    },

    isPossibleToSwitchOffcloudTesting() {
      if (this.isCategoryTestCase) {
        return false
      }

      const testCase = this.testCases.find(x => x.id === this.currentTestTypeId);

      if (!testCase) {
        return false;
      }

      return testCase.resourceType === "explorer";
    },
  },

  methods: {
    setScheduledTestName(id) {
      let name = ''
      if (this.isCategoryTestCase) {
        name = `Category: ${id || ''}`
      } else {
        name = this.isInitialTestCase(id) ? this.scheduledTest.name : this.getTestCaseNameById(id)
      }
      this.dataScheduledTest.name = name
    },

    getTestCaseNameById(id) {
      return this.testCases?.find(x => x.id === id)?.name || this.testCases?.[0]?.name || ''
    },

    isInitialTestCase(id) {
      return Boolean(this.scheduledTest.testCase.id) && this.scheduledTest.testCase.id === id
    },

    setDefaultTestTypeData() {
      this.setScheduledTestName(this.currentTestTypeId)
      if (this.isCategoryTestCase) {
        this.dataScheduledTest.offcloud = false;
      }
    },

    getTestType(testCase) {
      if (testCase?.id) {
        return SCHEDULED_TEST_TYPES.TEST_CASE.value
      } else if (testCase?.category) {
        return SCHEDULED_TEST_TYPES.CATEGORY.value
      }
    },

    initTestType() {
      this.currentTestType = this.getTestType(this.dataScheduledTest.testCase)
    },

    prepareTestTypeData(data) {
      if (this.isCategoryTestCase) {
        delete data.testCase.id
        return data
      }

      delete data.testCase.category
      return data
    },

    closeThisDialog({ wasSaved }) {
      this.$emit('input', false);
      if (this.closeMe) {
        this.closeMe({wasSaved})
      }
    },

    async save() {
      // eslint-disable-next-line no-undef
      let data = structuredClone(this.dataScheduledTest)
      data = this.prepareTestTypeData(data)
      data = this.updateWithNewSchedulingData(data)

      this.isSaveLoading = true
      if (!data.id) {
        cockpitTimeSchedulingService.createScheduledTest(data, () => {
          this.$emit('save');
          this.closeThisDialog({ wasSaved: true });
          this.isSaveLoading = false
        }, () => {
            this.isSaveLoading = false
          });
      } else {
        cockpitTimeSchedulingService.updateScheduledTest(data, () => {
          this.$emit('save');
          this.closeThisDialog({ wasSaved: true, });
        }, () => {
            this.isSaveLoading = false
          });
      }
    },
  },
});
</script>

<style lang="scss">
.schedule-tests-dialog {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__test-types-radios {
    align-items: center;

    & .v-input {
      padding-top: 0;
    }

    & .help-button {
      margin-top: 0;
    }
  }

  &__type-radios {
    align-items: center;

    & .v-input {
      padding-top: 0;
    }

    & .help-button {
      margin-top: 0;
    }
  }
}

.start-parameters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>

